'use client';
import { InputStepperEntity } from '@entities/desktop/input-stepper';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Product } from '@/shared/types/common';
import { ButtonStepper } from '@/shared/ui/desktop/button-stepper';
import { InputStepper } from '@/shared/ui/desktop/input-stepper';
import { useStore } from '@/store/context';

import styles from './adjust-item-quantity.module.scss';

type AdjustItemQuantityProps = {
  item: Product;
  minQuantity?: number;
  maxQuantity?: number;
  className?: string;
  status?: 'unavailable';
};

export const AdjustItemQuantityFeature = observer(
  ({
    item,
    minQuantity = 1,
    maxQuantity = 1,
    status,
    className,
  }: AdjustItemQuantityProps) => {
    const cart = useStore()?.cart;
    const cartItem = cart?.items?.find(
      currentItem => currentItem.id === item.id,
    );

    const handleDecrease = () => {
      if (cartItem?.count === 1) {
        cart?.removeFromCart(item.id);
      } else {
        cart?.decreaseQuantity(item.id);
      }
    };

    return (
      <InputStepperEntity
        className={cn(styles['container'], className)}
        leftSlot={
          <ButtonStepper
            stepperType={'minus'}
            disabled={
              Number(cartItem?.count) <= minQuantity || status === 'unavailable'
            }
            onPress={handleDecrease}
          />
        }
        middleSlot={
          <InputStepper
            value={cartItem?.count ?? 0}
            setValue={value => {
              cart?.setQuantity(item.id, value);
            }}
          />
        }
        rightSlot={
          <ButtonStepper
            stepperType={'plus'}
            disabled={
              Number(cartItem?.count) >= maxQuantity || status === 'unavailable'
            }
            onPress={() => {
              cart?.addToCart({
                id: item.id,
                count: 1,
                price: item.price!,
                status: null,
              });
            }}
          />
        }
      />
    );
  },
);
