'use client';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MouseEvent } from 'react';

import { Product } from '@/shared/types/common';
import { Button } from '@/shared/ui/button';
import { Illustration } from '@/shared/ui/illustration';
import { useStore } from '@/store/context';

import styles from './add-to-cart.module.scss';

export type AddToCartFeatureProps = {
  item: Product;
};

export const AddToCartFeature = observer(({ item }: AddToCartFeatureProps) => {
  const cart = useStore()?.cart;
  const cartItem = cart?.items?.find(
    currentItem => currentItem?.id === item.id,
  );
  const handleActionButtonClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e?.stopPropagation();
    cart?.addToCart({
      ...item,
      status: null,
      count: 1,
      price: item.price ?? 0,
    });
  };

  if (cartItem) {
    return (
      <AdjustItemQuantityFeature
        item={item}
        minQuantity={0}
        maxQuantity={item.prices?.length}
        className={styles['quantity-container']}
      />
    );
  }

  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.98 }}
      iconLeft={<Illustration name={'cart-plus'} spriteName={'icons'} />}
      width={'full-w'}
      className={cn(styles['action-btn'])}
      onClick={handleActionButtonClick}
      text={'Add to cart'}
    />
  );
});
