import cn from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { MouseEvent } from 'react';

import { Badge, TagCategory } from '@/shared/ui';
import { TagRarity } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';

import { TabsContext, useTabsContext } from './context/tabs-context';
import styles from './tabs.module.scss';
import {
  TabsItemCardProps,
  TabsItemFilterProps,
  TabsItemNavigationProps,
  TabsItemProfileProps,
  TabsItemProps,
  TabsItemSearchProps,
  TabsProps,
} from './tabs.types';

export const Tabs = <T,>({
  defaultActiveTab,
  children,
  onTabsChange,
  className,
  isSelectable,
  ...props
}: TabsProps<T>) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const setActive = useCallback(
    (value: T) => {
      setActiveTab(value);
    },
    [activeTab],
  );

  const value = useMemo(
    () => ({
      activeTab,
      setActive,
      defaultActiveTab,
      onTabsChange,
      isSelectable,
    }),
    [activeTab, setActive, defaultActiveTab, isSelectable],
  );

  return (
    <TabsContext.Provider value={value}>
      <div
        id={'tabs-container'}
        className={cn(styles.container, className)}
        {...props}
      >
        {children}
      </div>
    </TabsContext.Provider>
  );
};

// eslint-disable-next-line react/display-name
export const TabsItem = ({
  value,
  children,
  variant = 'primary',
  className,
  iconsLeft = null,
  iconsRight = null,
  width = 'content',
  disabled,
  isSelected,
  tagCategory,
  ...props
}: TabsItemProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isSelectable, activeTab, setActive, onTabsChange } = useTabsContext();

  const isActive = isSelected || (isSelectable && value === activeTab);

  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e?.stopPropagation();
    setActive(value);
    onTabsChange && onTabsChange(value);
  };

  const tabsItemClasses = cn(
    styles['tabs-item'],
    styles[`width--${width}`],
    { [styles['disabled']]: disabled },
    { [styles['tab-icon']]: iconsRight || iconsLeft },
    { [styles['active']]: isActive },
    styles[`variant--${variant}`],
    className,
  );

  return (
    <button
      disabled={disabled}
      className={tabsItemClasses}
      onClick={handleClick}
      {...props}
    >
      {Array.isArray(tagCategory) &&
        tagCategory.length > 0 &&
        tagCategory?.map((tag, idx) => {
          return (
            <TagCategory
              id={'tag-category'}
              className={styles['tag-category']}
              key={`${tag}-${idx}`}
              variant={tag}
            />
          );
        })}
      {tagCategory && !Array.isArray(tagCategory) && (
        <TagCategory
          id={'tag-category'}
          className={styles['tag-category']}
          variant={tagCategory}
        />
      )}
      {!Array.isArray(tagCategory) && iconsLeft}
      {!Array.isArray(tagCategory) && children && (
        <Typography
          style={{ textTransform: 'capitalize', width: 'max-content' }}
        >
          {children}
        </Typography>
      )}
      {iconsRight}
    </button>
  );
};

// eslint-disable-next-line react/display-name
export const TabsItemCard = ({
  value,
  className,
  disabled,
  flyable,
  rideable,
  pumping,
  tagSize = 18,
  ...props
}: TabsItemCardProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { activeTab, setActive, onTabsChange } = useTabsContext();
  const isActive = value === activeTab;

  const tabsItemCardClasses = cn(
    styles['tabs-item-card'],
    { [styles['disabled']]: disabled },
    { [styles['active']]: isActive },
    className,
  );

  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e?.stopPropagation();
    setActive(value);
    onTabsChange && onTabsChange(value);
  };

  return (
    <button onClick={handleClick} className={tabsItemCardClasses} {...props}>
      {flyable && <TagCategory size={tagSize} variant={'fly'} />}
      {rideable && <TagCategory size={tagSize} variant={'ride'} />}
      {pumping !== 'default' && (
        <TagCategory size={tagSize} variant={pumping} />
      )}
    </button>
  );
};

// eslint-disable-next-line react/display-name
export const TabsItemFilter = ({
  value,
  className,
  disabled,
  icon = null,
  children,
  ...props
}: TabsItemFilterProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { activeTab, setActive, onTabsChange } = useTabsContext();
  const isActive = value === activeTab;

  const tabsItemCardClasses = cn(
    styles['tabs-item-filter'],
    { [styles['disabled']]: disabled },
    { [styles['active']]: isActive },
    className,
  );

  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    setActive(value);
    onTabsChange && onTabsChange(value);
  };

  return (
    <button onClick={handleClick} className={tabsItemCardClasses} {...props}>
      {children && <Typography>{children}</Typography>}
      {icon}
    </button>
  );
};
// eslint-disable-next-line react/display-name
export const TabsItemNavigation = ({
  value,
  className,
  disabled,
  icon = null,
  children,
  isActive,
  withBadge,
  badgeCount,
  ...props
}: TabsItemNavigationProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { activeTab, setActive, onTabsChange } = useTabsContext();

  const tabsItemCardClasses = cn(
    styles['tabs-item-navigation'],
    { [styles['disabled']]: disabled },
    { [styles['active']]: isActive },
    className,
  );

  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    setActive(value);
    onTabsChange && onTabsChange(value);
  };

  const badgeStyles = cn(
    styles['badge'],
    { [styles['one-number']]: badgeCount && badgeCount < 10 },
    { [styles['two-numbers']]: badgeCount && badgeCount >= 10 },
    { [styles['three-numbers']]: badgeCount && badgeCount > 99 },
  );

  return (
    <button onClick={handleClick} className={tabsItemCardClasses} {...props}>
      {icon}
      {withBadge && <Badge count={badgeCount} className={badgeStyles} />}
      {children && <Typography>{children}</Typography>}
    </button>
  );
};

// eslint-disable-next-line react/display-name
export const TabsItemSearch = ({
  className,
  children,
  onPress,
  ...props
}: TabsItemSearchProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const tabsItemSearchClasses = cn(styles['tabs-item-search'], className);

  return (
    <button
      onClick={() => onPress()}
      className={tabsItemSearchClasses}
      {...props}
    >
      {children && (
        <Typography style={{ width: 'max-content' }}>{children}</Typography>
      )}
    </button>
  );
};
// eslint-disable-next-line react/display-name
export const TabsItemProfile = ({
  className,
  children,
  onClick = () => {},
  disabled,
  isActive,
  icon,
  value,
  text,
  ...props
}: TabsItemProfileProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { activeTab, setActive, onTabsChange } = useTabsContext();

  const tabsItemProfileClasses = cn(
    styles['tabs-item-profile'],
    { [styles['disabled']]: disabled },
    { [styles['active']]: isActive },
    className,
  );

  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    setActive(value);
    onTabsChange && onTabsChange(value);
    onClick(e);
  };

  return (
    <button onClick={handleClick} className={tabsItemProfileClasses} {...props}>
      {icon}
      {children}
      {text && (
        <Typography className={styles['tab-profile-text']}>{text}</Typography>
      )}
    </button>
  );
};
