'use client';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { motion } from 'framer-motion';
import React, { ReactNode, useState } from 'react';

import { WithCurrency } from '@/shared/hocs';
import {
  Item,
  ItemRarityLiveFeed,
  ProgressIndicator,
  TagCategory,
  TagInfo,
  TagTimer,
  Typography,
} from '@/shared/ui';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';

import styles from './card-inventory.module.scss';
import {
  CardInventoryGiftProps,
  CardInventoryItemInfoProps,
  CardInventoryItemPropertiesProps,
  CardInventoryItemProps,
  CardInventoryLimitedProps,
} from './card-inventory.types';
import { CardInventoryProps } from './card-inventory.types';

export const CardInventoryEntity = ({
  topBottomLeftSlot,
  topMiddleSlot,
  topRightSlot,
  bottomRightSlot,
  bottomLeftSlot,
  middleSlot,
  disabled,
  ...props
}: CardInventoryProps) => {
  return (
    <motion.article
      className={cn(styles['card-inventory'], {
        [styles['disabled']]: disabled,
      })}
      {...props}>
      <div className={styles['top-slot-wrapper']}>
        <div className={styles['top-slot--right']}>{topRightSlot}</div>
        <div className={styles['top-slot']}>{topMiddleSlot}</div>
        <div id={'top-slot-bottom'} className={styles['top-slot-bottom']}>
          {topBottomLeftSlot}
        </div>
      </div>

      <div className={styles['middle-slot']}>{middleSlot}</div>
      <div className={styles['bottom-slot']}>
        {bottomLeftSlot}
        {bottomRightSlot}
      </div>
    </motion.article>
  );
};

CardInventoryEntity.Item = ({
  className,
  rare,
  src,
  game,
  ...props
}: CardInventoryItemProps) => {
  const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div
      className={cn(
        styles['item-container'],
        styles['item-paddings'],
        className,
      )}
      {...props}>
      <ItemRarityLiveFeed
        isMM2Variant={game === 'mm2'}
        colorScheme={colorScheme}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        size={'120'}
        variant={rare}
      />
      <Item className={styles['item']} size={'96'} src={src} />
    </div>
  );
};

CardInventoryEntity.ItemInfo = ({
  title,
  price,
  type,
  age,
}: CardInventoryItemInfoProps) => {
  return (
    <div className={styles['item-info-container']}>
      <div className={styles['info']}>
        <div className={styles['price']}>
          <Typography className={styles['price--current']}>
            <WithCurrency>{price}</WithCurrency>
          </Typography>
          {price && (
            <Typography
              decoration={'line-through'}
              className={styles['price--old']}>
              <WithCurrency>{price}</WithCurrency>
            </Typography>
          )}
        </div>
        {title && <Typography className={styles['title']}>{title}</Typography>}
          <Typography className={styles['subtitle']}>
            {formatAge(age ?? type)}
          </Typography>
      </div>
    </div>
  );
};

CardInventoryEntity.ItemProperties = ({
  flyable,
  rideable,
  ...props
}: CardInventoryItemPropertiesProps) => {
  return (
    <div className={styles['item-properties-container']} {...props}>
      {flyable && (
        <TagCategory className={styles['category']} variant={'fly'} />
      )}
      {rideable && (
        <TagCategory className={styles['category']} variant={'ride'} />
      )}
    </div>
  );
};

CardInventoryEntity.SpendingProgress = ({
  price,
}: {
  price: { current: number; total: number };
}) => {
  return (
    <div className={styles['spending-progress']}>
      <div className={styles['price']}>
        <Typography className={styles['current']}>
          <WithCurrency>{price.current}</WithCurrency>
        </Typography>
        <Typography className={styles['total']}>/ {price.total}</Typography>
      </div>

      <ProgressIndicator
        staticWidth={(price.current / price.total) * 100}
        variant={'warn'}
      />

      <Typography className={styles['content']}>
        To purchase this item, you need to spend at least{' '}
        <WithCurrency>{price.total}</WithCurrency> on other items
      </Typography>
    </div>
  );
};

CardInventoryEntity.Limited = ({
  flyable,
  rideable,
  rare,
  src,
  title,
  price,
  spendQuantityTotal,
  spendQuantityCurrent,
  claimWithin,
  disabled,
  game,
  age,
  type,
  handleTimesUp,
  ...props
}: CardInventoryLimitedProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <CardInventoryEntity
      onClick={props.onClick}
      disabled={disabled}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      topMiddleSlot={
        isHovered ? (
          <CardInventoryEntity.SpendingProgress
            price={{ total: spendQuantityTotal, current: spendQuantityCurrent }}
          />
        ) : (
          <CardInventoryEntity.Item src={src} rare={rare} game={game} />
        )
      }
      topBottomLeftSlot={
        isHovered ? null : <TagInfo variant={'limited'}>Limited</TagInfo>
      }
      middleSlot={
        isHovered ? (
          <TagInfo variant={'limited'}>Limited</TagInfo>
        ) : (
          <CardInventoryEntity.ItemInfo
            title={title}
            price={price}
            age={age}
            type={type}
          />
        )
      }
      bottomLeftSlot={
        <CardInventoryEntity.ItemProperties
          flyable={flyable}
          rideable={rideable}
        />
      }
      bottomRightSlot={
        <TagTimer
          enableColorDiffByTime
          duration={claimWithin}
          onTimesUp={handleTimesUp}
        />
      }
    />
  );
};

CardInventoryEntity.Gift = ({
  src,
  rare,
  rideable,
  flyable,
  title,
  price,
  age,
  type,
  timesUp,
  claimWithin,
  disabled,
  checked,
  game,
  toggleSelected,
  handleTimesUp,
  ...props
}: CardInventoryGiftProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <CardInventoryEntity
      onClick={props.onClick}
      disabled={disabled}
      topRightSlot={
        !timesUp && <Checkbox checked={checked} onChange={toggleSelected} />
      }
      topMiddleSlot={
        <CardInventoryEntity.Item src={src} rare={rare} game={game} />
      }
      topBottomLeftSlot={
        !timesUp ? (
          <TagInfo variant={'available'} />
        ) : (
          <TagInfo variant={'gift'}>Time’s up!</TagInfo>
        )
      }
      middleSlot={
        <CardInventoryEntity.ItemInfo
          title={title}
          price={price}
          age={age}
          type={type}
        />
      }
      bottomLeftSlot={
        <CardInventoryEntity.ItemProperties
          rideable={rideable}
          flyable={flyable}
        />
      }
      bottomRightSlot={
        <TagTimer
          enableColorDiffByTime
          duration={claimWithin}
          onTimesUp={handleTimesUp}
        />
      }
    />
  );
};

CardInventoryEntity.Default = ({
  rare,
  flyable,
  rideable,
  src,
  title,
  price,
  timesUp,
  claimWithin,
  disabled,
  checked,
  game,
  age,
  type,
  toggleSelected,
  handleTimesUp,
  ...props
}: CardInventoryGiftProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <CardInventoryEntity
      onClick={props.onClick}
      disabled={disabled}
      topMiddleSlot={
        <CardInventoryEntity.Item game={game} src={src} rare={rare} />
      }
      topBottomLeftSlot={
        timesUp && <TagInfo variant={'info'}>Time’s up!</TagInfo>
      }
      topRightSlot={
        !timesUp && <Checkbox checked={checked} onChange={toggleSelected} />
      }
      middleSlot={
        <CardInventoryEntity.ItemInfo
          title={title}
          price={price}
          age={age}
          type={type}
        />
      }
      bottomLeftSlot={
        <CardInventoryEntity.ItemProperties
          flyable={flyable}
          rideable={rideable}
        />
      }
      bottomRightSlot={
        <TagTimer duration={claimWithin} onTimesUp={handleTimesUp} />
      }
    />
  );
};
