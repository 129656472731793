import cn from 'clsx';

import { Item, TagInfo } from '@/shared/ui';

import stylesDesktop from './card-item-top.module.scss';
import { CardItemTopProps } from './card-item-top.types';

export const CardItemTop = ({
  src,
  backgroundIllustration,
  // tagsInfo,
  topRightSlot,
  customStyles,
  className,
  variant,
  isMM2Variant,
  ...props
}: CardItemTopProps) => {
  const styles = customStyles || stylesDesktop;

  return (
    <div
      onClick={props.onClick}
      className={cn(
        styles['item'],
        {
          [styles['tertiary']]: variant === 'tertiary',
          [styles['quaternary']]: variant === 'quaternary',
        },
        className,
      )}
    >
      {backgroundIllustration}
      <Item className={styles['item-shop']} size={'68'} src={src} />
      {/*{Array.isArray(tagsInfo) && tagsInfo.length > 0 && (*/}
      {/*  <div*/}
      {/*    id={'tags-info-container'}*/}
      {/*    className={cn(styles['tags-info-container'])}*/}
      {/*  >*/}
      {/*    {tagsInfo.map((tag, idx) => {*/}
      {/*      return (*/}
      {/*        <TagInfo*/}
      {/*          isMM2Variant={isMM2Variant}*/}
      {/*          key={`tag-${idx}-info`}*/}
      {/*          id={'tag-info'}*/}
      {/*          className={cn(styles['tag-info'])}*/}
      {/*          variant={tag.variant}*/}
      {/*        >*/}
      {/*          {tag.text}*/}
      {/*        </TagInfo>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*)}*/}

      {topRightSlot && (
        <div id={'top-right-slot'} className={cn(styles['fav-btn-container'])}>
          {topRightSlot}
        </div>
      )}
    </div>
  );
};
