'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { Product } from '@/shared/types/common';
import { Tabs, TabsItemCard } from '@/shared/ui/tabs';

import styles from './select-pet-properties.module.scss';

type SelectPetPropertiesFeatureProps = {
  products: Product[];
  href: string;
  itemType: string;
  onTabChange: (product: Product) => void;
};

export const SelectPetPropertiesFeature = observer(
  ({
    products,
    itemType,
    href,
    onTabChange,
  }: SelectPetPropertiesFeatureProps) => {
    const tabs =
      Array?.isArray(products) && products.length > 0 ? products : [];

    const defaultActiveTab = products[0].id;

    const router = useRouter();

    return (
      Array.isArray(tabs) && (
        <Tabs<string | number>
          defaultActiveTab={defaultActiveTab}
          onTabsChange={id => {
            onTabChange(products.find(prod => prod.id === id)!);
          }}
        >
          {itemType === 'pet' &&
            tabs.map((tab, idx) => {
              return (
                <TabsItemCard
                  key={`tab.value-${idx}`}
                  className={cn(styles['tab-item'])}
                  value={tab.id}
                  tagSize={18}
                  flyable={tab.flyable!}
                  rideable={tab.rideable!}
                  pumping={tab.pumping!}
                />
              );
            })}

          <TabsItemCard
            className={cn(styles['tab-item'])}
            value={'menu'}
            onClick={() => router.push(href)}
            tagSize={18}
            pumping={'menu'}
          />
        </Tabs>
      )
    );
  },
);
